import {createRouter, createWebHashHistory} from 'vue-router';

const routes = [
    {
        path: '/login',
        name: 'Login',
        component: () => import('./views/Login.vue'),
        meta: {
            title: '登录',
            keepAlive: false,
        },
    },
    {
        path: '/',
        redirect: '/dashboard',
        component: () => import('./views/Layout.vue'),
        children: [
            {
                name: 'Dashboard',
                path: '/dashboard',
                component: () => import('./views/Dashboard/Index.vue'),
                meta: {
                    title: '首页',
                    keepAlive: false,
                },
            },

            {
                name: 'User',
                path: '/user/list',
                component: () => import('./views/User/List.vue'),
                meta: {
                    title: '用户列表',
                    keepAlive: false,
                },
            },
            {
                name: 'UserInfo',
                path: '/user/info',
                component: () => import('./views/User/Info.vue'),
                meta: {
                    title: '用户详情',
                    keepAlive: false,
                },
            },

            {
                name: 'MemberOrder',
                path: '/member_order/list',
                component: () => import('./views/MemberOrder/List.vue'),
                meta: {
                    title: '会员订单',
                    keepAlive: false,
                },
            },

            {
                name: 'Video',
                path: '/video/list',
                component: () => import('@/views/Videos/List.vue'),
                meta: {
                    title: '视频管理',
                    keepAlive: true,
                },
            },
            {
                name: 'VideoAdd',
                path: '/video/add',
                component: () => import('@/views/Videos/Info.vue'),
                meta: {
                    title: '添加视频',
                    keepAlive: false,
                },
            },
            {
                name: 'VideoDetail',
                path: '/video/info',
                component: () => import('@/views/Videos/Info.vue'),
                meta: {
                    title: '视频详情',
                    keepAlive: true,
                },
            },

            {
                name: 'SysNotice',
                path: '/notice/list',
                component: () => import('@/views/Notice/List.vue'),
                meta: {
                    title: '公告管理',
                    keepAlive: false,
                },
            },

            {
                name: 'SysNoticeAdd',
                path: '/notice/add',
                component: () => import('@/views/Notice/Info.vue'),
                meta: {
                    title: '添加公告',
                    keepAlive: false,
                },
            },
            {
                name: 'SysNoticeDetail',
                path: '/notice/info',
                component: () => import('@/views/Notice/Info.vue'),
                meta: {
                    title: '公告详情',
                    keepAlive: true,
                },
            },

            {
                name: 'ChatMessage',
                path: '/chat/index',
                component: () => import('./views/ChatMessage/Index.vue'),
                meta: {
                    title: '用户消息',
                    keepAlive: false,
                },
            },

            {
                name: 'AdminList',
                path: '/admin/list',
                component: () => import('./views/Admin/List.vue'),
                meta: {
                    title: '管理员',
                    keepAlive: false,
                },
            },
            {
                name: 'AdminAdd',
                path: '/admin/add',
                component: () => import('./views/Admin/Info.vue'),
                meta: {
                    title: '添加管理员',
                    keepAlive: false,
                },
            },
            {
                name: 'AdminInfo',
                path: '/admin/info',
                component: () => import('./views/Admin/Info.vue'),
                meta: {
                    title: '管理员详情',
                    keepAlive: false,
                },
            },

            {
                name: 'SysGlobalConfig',
                path: '/sys/globalconfig/list',
                component: () => import('./views/Sys/GlobalConfig/List.vue'),
                meta: {
                    title: '全局配置',
                    keepAlive: false,
                },
            },
            {
                name: 'SysGlobalConfigInfo',
                path: '/sys/globalconfig/info',
                component: () => import('./views/Sys/GlobalConfig/Info.vue'),
                meta: {
                    title: '全局配置详情',
                    keepAlive: false,
                },
            },
            {
                name: 'SysGlobalConfigAdd',
                path: '/sys/globalconfig/add',
                component: () => import('./views/Sys/GlobalConfig/Info.vue'),
                meta: {
                    title: '添加全局配置',
                    keepAlive: false,
                },
            },

            {
                name: 'SysMenu',
                path: '/sys/menu/list',
                component: () => import('./views/Sys/Menu/List.vue'),
                meta: {
                    title: '菜单管理',
                    keepAlive: false,
                },
            },

            {
                name: 'SysPermission',
                path: '/sys/permission/list',
                component: () => import('./views/Sys/Permission/List.vue'),
                meta: {
                    title: '权限管理',
                    keepAlive: false,
                },
            },

            {
                name: 'SysRole',
                path: '/sys/role/list',
                component: () => import('./views/Sys/Role/List.vue'),
                meta: {
                    title: '角色管理',
                    keepAlive: false,
                },
            },

            {
                name: 'Creators',
                path: '/creators/list',
                component: () => import('./views/Creators/List.vue'),
                meta: {
                    title: '导演',
                    keepAlive: false,
                },
            },
            {
                name: 'CreatorsAdd',
                path: '/creators/add',
                component: () => import('./views/Creators/Info.vue'),
                meta: {
                    title: '添加导演',
                    keepAlive: false,
                },
            },
            {
                name: 'CreatorsInfo',
                path: '/creators/info',
                component: () => import('./views/Creators/Info.vue'),
                meta: {
                    title: '导演详情',
                    keepAlive: false,
                },
            },

            {
                name: 'Actors',
                path: '/actors/list',
                component: () => import('./views/Actors/List.vue'),
                meta: {
                    title: '演员',
                    keepAlive: false,
                },
            },
            {
                name: 'ActorsAdd',
                path: '/Actors/add',
                component: () => import('./views/Actors/Info.vue'),
                meta: {
                    title: '添加演员',
                    keepAlive: false,
                },
            },
            {
                name: 'ActorsInfo',
                path: '/actors/info',
                component: () => import('./views/Actors/Info.vue'),
                meta: {
                    title: '演员详情',
                    keepAlive: false,
                },
            },

            {
                name: 'Genres',
                path: '/genres/list',
                component: () => import('./views/Genres/List.vue'),
                meta: {
                    title: '影视类型',
                    keepAlive: false,
                },
            },
            {
                name: 'GenresAdd',
                path: '/genres/add',
                component: () => import('./views/Genres/Info.vue'),
                meta: {
                    title: '添加影视类型',
                    keepAlive: false,
                },
            },
            {
                name: 'GenresInfo',
                path: '/genres/info',
                component: () => import('./views/Genres/Info.vue'),
                meta: {
                    title: '影视类型详情',
                    keepAlive: false,
                },
            },

            {
                name: 'Tags',
                path: '/tags/list',
                component: () => import('./views/Tags/List.vue'),
                meta: {
                    title: '标签',
                    keepAlive: false,
                },
            },
            {
                name: 'TagsAdd',
                path: '/tags/add',
                component: () => import('./views/Tags/Info.vue'),
                meta: {
                    title: '添加标签',
                    keepAlive: false,
                },
            },
            {
                name: 'TagsInfo',
                path: '/tags/info',
                component: () => import('./views/Tags/Info.vue'),
                meta: {
                    title: '标签类型',
                    keepAlive: false,
                },
            },


            {
                name: 'AdSummary',
                path: '/ad/summary',
                component: () => import('./views/Ad/Summary.vue'),
                meta: {
                    title: '广告统计',
                    keepAlive: false,
                },
            },

            {
                name: 'Ad',
                path: '/ad/list',
                component: () => import('./views/Ad/List.vue'),
                meta: {
                    title: '广告投放',
                    keepAlive: false,
                },
            },
            {
                name: 'AdAdd',
                path: '/ad/add',
                component: () => import('./views/Ad/Info.vue'),
                meta: {
                    title: '添加广告',
                    keepAlive: false,
                },
            },
            {
                name: 'AdInfo',
                path: '/ad/info',
                component: () => import('./views/Ad/Info.vue'),
                meta: {
                    title: '广告详情',
                    keepAlive: false,
                },
            },

            {
                name: 'Advertiser',
                path: '/advertiser/list',
                component: () => import('./views/Advertiser/List.vue'),
                meta: {
                    title: '广告主',
                    keepAlive: false,
                },
            },

            {
                name: 'AdShowLogs',
                path: '/ad/logs/showlist',
                component: () => import('./views/Ad/Logs/Show.vue'),
                meta: {
                    title: '广告展示日志',
                    keepAlive: false,
                },
            },
            {
                name: 'AdClickLogs',
                path: '/ad/logs/clicklist',
                component: () => import('./views/Ad/Logs/Click.vue'),
                meta: {
                    title: '广告点击日志',
                    keepAlive: false,
                },
            },
            {
                name: 'AdType',
                path: '/ad/type',
                component: () => import('./views/Ad/Type.vue'),
                meta: {
                    title: '广告类型',
                    keepAlive: false,
                },
            },
            {
                name: 'AdOrder',
                path: '/ad/order/list',
                component: () => import('./views/Ad/Order/List.vue'),
                meta: {
                    title: '广告订单',
                    keepAlive: false,
                },
            },

            {
                name: 'Feedback',
                path: '/feedback/list',
                component: () => import('./views/Feedback/List.vue'),
                meta: {
                    title: '意见反馈',
                    keepAlive: false,
                },
            },

            {
                name: 'UserSummary',
                path: '/summary/user',
                component: () => import('./views/Summary/UserSummary.vue'),
                meta: {
                    title: '用户统计',
                    keepAlive: false,
                },
            },
            {
                name: 'DomainSummary',
                path: '/summary/domain',
                component: () => import('./views/Summary/DomainSummary.vue'),
                meta: {
                    title: '域名监控',
                    keepAlive: false,
                },
            },
        ]
    },
];

const router = createRouter({
    routes,
    history: createWebHashHistory(),
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            const position = {};
            if (to.hash) {
                position.selector = to.hash;
                if (document.querySelector(to.hash)) {
                    return position;
                }
                return false;
            }
        }
    },
});

router.beforeEach((to, from, next) => {
    const title = to.meta && to.meta.title;
    if (title) {
        document.title = title;
    }
    next();
});

router.afterEach((to) => {
    if (to.meta.keepAlive) {
        const scrollTop = sessionStorage.getItem(to.path) || 0;
        setTimeout(() => {
            window.scrollTo(0, Number(scrollTop));
        }, 0);
    } else {
        window.scrollTo(0, 0);
    }
});

export {router};
