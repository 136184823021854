<template>
  <router-view/>
</template>

<script>

export default {
  created() {

  },
}
</script>

<style lang="less">
body {
  margin: 0px;
  padding: 0px;
  background-color: #f2f3f5;
}
</style>